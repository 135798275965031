import React, { useState, useEffect,ReactElement, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm, Controller } from "react-hook-form";
import Grid from '@mui/material/Grid';
import $ from 'jquery';
import TextField from '@mui/material/TextField';
import { Button,Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
//import DatePicker  from './DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import { replaceAll } from "../../helpers/Tools";
import Alert from '@mui/material/Alert';
import moment from "moment";
import usePasajerosStore from "../../store/usePasajerosStore";


const tipoDocumentos = [
{
    value: '1',
    label: 'RUT',
},
{
    value: '2',
    label: 'Pasaporte',
}
];
const paises = [
{
    value: '1',
    label: 'Chile',
},
{
    value: '2',
    label: 'Perú',
},{
    value: '3',
    label: 'Argentina',
}
];
const estados = [
    {
        value: '1',
        label: 'Arica',
    },
    {
        value: '2',
        label: 'Parinacota',
    },{
        value: '3',
        label: 'Tarapacá',
    }
    ];

const ciudades = [
    {
        value: '1',
        label: 'Santiago',
    },
    {
        value: '2',
        label: 'Arica',
    }
    ];

    const { datosPasajeros } = usePasajerosStore.getState();
//export class PasajeroForm extends Component  {  
const PasajeroForm = React.forwardRef((props, ref) => {
    const infoPrevious = JSON.parse(localStorage.getItem("datosForm"));
    const sUsrAg = navigator.userAgent;
    const datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    const [error, setError] = useState(""); 
    const dispatch = useDispatch();
    const Paises = useSelector(
        (state) => state.Paises
      );
    const Estados = useSelector(
        (state) => state.Estados
      );  
    const Ciudades = useSelector(
        (state) => state.Ciudades
      );  
    const TipoDocumentos = useSelector(
        (state) => state.TipoDocumentos
      );  
    useEffect(() => {        
        dispatch({
            type: "TIPODOCUMENTOS_REQUEST",
            params: { }
        });
        dispatch({
            type: "PAISES_REQUEST",
            params: { }
        });
        dispatch({
            type: "ESTADOS_REQUEST",
            params: {
                paisId: 140
            },
        });
        if(props.pasajero.provincia>0){
            dispatch({
                type: "CIUDADES_REQUEST",
                params: {
                    estadoId: props.pasajero.provincia
                },
            });
        } else{
            Ciudades.data = [];
        }
    }, [dispatch]);    
    useEffect(() => {
    }, [TipoDocumentos.data]);
    useEffect(() => {
      }, [Paises.data]);
    useEffect(() => {
      }, [Estados.data]);
    useEffect(() => {
      }, [Ciudades.data]);
    const infoPreviousPasajeros = datosPasajeros;
    const validarTitularMismosDatosFunction = () =>{  
        setApellidosEmergencia("");
        setNombresEmergencia("");
        setTelefonoEmergencia("");
        setDireccion(""); 
        if(Array.isArray(infoPreviousPasajeros)){
            var titular = infoPreviousPasajeros.at(0);
            if(titular===undefined || titular===null){
                return false;
            }
            if(titular.mismosDatos === "true"){
                setApellidosEmergencia(titular.apellidosEmergencia);
                setNombresEmergencia(titular.nombresEmergencia);
                setTelefonoEmergencia(titular.telefonoEmergencia);
                setDireccion(titular.direccion);
                return true;
            }else{
                
                return false;
            }
        }else{
            return false;
        }
    }

    const validarTitularMismosDatosEmailFunction = () =>{  
        setEmail("");
        setConfirmarEmail("");        
        if(Array.isArray(infoPreviousPasajeros)){
            var titular = infoPreviousPasajeros.at(0);
            if(titular===undefined || titular===null){
                return false;
            }
            if(titular.mismosDatosEmail=== "true"){
                setEmail(titular.email);
                setConfirmarEmail(titular.email);
                return true;
            }else{
                
                return false;
            }
        }else{
            return false;
        }
    }
    

    const validarNoEsMismaIdentificacion = () =>{ 
        var yaExisteIdentificacion= false;         
        if(Array.isArray(infoPreviousPasajeros)){
            yaExisteIdentificacion = false;
            for(var i=0; i<infoPreviousPasajeros.length;i++){
                if(infoPreviousPasajeros[i].identificacion.trim()==identificacion.trim()){
                    yaExisteIdentificacion=true;
                }
            }
            
        }else{
            yaExisteIdentificacion= false;
        }
        return yaExisteIdentificacion;
    }
    const onTrigger = (event) => { 
        const formRef=document.getElementById("formularioIngresar");    
        event.preventDefault();       
        setError("");
        if(props.indiceMostrar===1){
            if(!phoneValidation(formRef.telefonoContacto.value)){
                setError("El número de teléfono ingresado no es válido");
                return;
            }  
        }
        if(errEmail!=""){
            setError("El email ingresado en el campo Confirmar Email no coincide con el del campo Email");
            return;
        }
        if(!documentoValidation(formRef.identificacion.value)){
            setError("El número de documento ingresado no es válido");
            return;
        } 
        if (!handleValidation()) {
            setError("El número de RUT ingresado no es válido");
            return;
        } 
        if(validarNoEsMismaIdentificacion()){
            setError("El número de RUT ya pertenece a otro Pasajero");
            return;
        }
        event = formRef;
        if (event.nombre.value.length<2) {
            setError("Nombre no válido");
            return;
        }
        if (event.apellido1.value.length<2) {
            setError("Apellido paterno no válido");
            return;
        }
        if (event.apellido2.value.length<1) {
            setError("Apellido materno no válido");
            return;
        }
        const minDate = moment().subtract(69,"year").subtract(1,"day");
        const maxDate = props.indiceMostrar === 1 ? moment().subtract(18,"year").add(1,"day") : moment();
        const fechaNacimiento = moment(event.fechanacimiento.value);
        if (fechaNacimiento.isBefore(minDate) || fechaNacimiento.isAfter(maxDate)) {
            setError("Debe seleccionar una fecha entre los 18 y 69 años para poder contratar un seguro");
            return;
        }        
        var pasajero={
            indice: props.pasajero.indice,
            nombre: event.nombre.value,
            apellido1: event.apellido1.value, 
            apellido2: event.apellido2.value, 
            tipoDocumento: event.tipoDocumento.value,
            tipoDocumentoLabel: tipoDocumentos.find(x=>x.value===event.tipoDocumento.value).label,
            identificacion: event.identificacion.value, 
            direccion: event.direccion.value, 
            ciudad: event.ciudad.value, 
            ciudadLabel: Array.isArray(Ciudades.data) ? (Ciudades.data.find(x=>x.ciudadId == event.ciudad.value)).nombre : ciudades.find(x=>x.value==event.ciudad.value).label,
            pais: event.pais.value, 
            paisLabel: Array.isArray(Paises.data)? Paises.data.find(x=>x.paisId == event.pais.value).nombre : paises.find(x=>x.value==event.pais.value).label,
            fechanacimiento: moment(event.fechanacimiento.value).format('YYYY-MM-DD'),
            provincia: event.provincia.value, 
            provinciaLabel: Array.isArray(Estados.data)? Estados.data.find(x=>x.estadoId == event.provincia.value).nombre : estados.find(x=>x.value==event.provincia.value).label,
            email: event.email.value, 
            sexo: event.sexo.value,
            nombresEmergencia:  event.nombreContacto.value , 
            telefonoEmergencia: event.telefonoContacto.value, 
            apellidosEmergencia: event.apellidoContacto.value,
            mismosDatos: event.mismosDatos === undefined || event.mismosDatos==null ? false : event.mismosDatos.value,
            mismosDatosEmail: event.mismosDatosEmail === undefined || event.mismosDatosEmail==null ? false : event.mismosDatosEmail.value,
        };
        resetForm(); 
        props.parentCallback(pasajero);              
    }
    
    const [nombre, setNombre] = useState(props.pasajero.nombre);    
    const [apellido1, setApellido1] = useState(props.pasajero.apellido1);
    const [apellido2, setApellido2] = useState(props.pasajero.apellido2);
    const [identificacion, setIdentificacion] = useState(props.pasajero.identificacion);
    const [tipoDocumento, setTipoDocumento] = useState(props.pasajero.tipoDocumento==null || props.pasajero.tipoDocumento==undefined || props.pasajero.tipoDocumento=="" ? 1 : props.pasajero.tipoDocumento);//
    const [email, setEmail] = useState(props.pasajero.email);
    const [confirmaremail, setConfirmarEmail] = useState(props.pasajero.confirmaremail);
    const [sexo, setSexo] = useState(props.pasajero.sexo==null || props.pasajero.sexo==undefined ? "" : props.pasajero.sexo);
    const [direccion, setDireccion] = useState(props.pasajero.direccion);
    const [nombresEmergencia, setNombresEmergencia] = useState(props.pasajero.nombresEmergencia);
    const [apellidosEmergencia, setApellidosEmergencia] = useState(props.pasajero.apellidosEmergencia);
    const [telefonoEmergencia, setTelefonoEmergencia] = useState(props.pasajero.telefonoEmergencia);
    const [selectedValuePais, setSelectedValuePais] = useState(props.pasajero.pais==null || props.pasajero.pais==undefined || props.pasajero.pais=="" ? 140 : props.pasajero.pais);//
    const [selectedValueEstado, setSelectedValueEstado] = useState(props.pasajero.provincia);
    const [selectedValueCiudad, setSelectedValueCiudad] = useState(props.pasajero.ciudad);
    const [fechaNacimiento, setFechaNacimiento] = useState(props.pasajero.fechaNacimiento != null ? moment(props.pasajero.fechaNacimiento) : moment());
    const [mismosDatos, setMismosDatos] = useState(props.pasajero.mismosDatos);
    const [validarTitularMismosDatos, setvalidarTitularMismosDatos] = useState(validarTitularMismosDatosFunction);
    const [mismosDatosEmail, setmismosDatosEmail] = useState(props.pasajero.mismosDatosEmail);
    const [validarTitularMismosDatosEmail, setvalidarTitularMismosDatosEmail] = useState(validarTitularMismosDatosEmailFunction);
    const [errEmail, setErrEmail] = useState("");

    const handleChange = (event) => {
        let iden=event.target.value;
        setSelectedValuePais(iden);  
    }; 
    const handleChange2 = (event) => {
        let iden=event.target.value;
        setSelectedValueEstado(iden);
        dispatch({
            type: "CIUDADES_REQUEST",
            params: {
                estadoId: iden
            },
          });
    }; 
    const handleChange3 = (event) => {
        let iden=event.target.value;
        setSelectedValueCiudad(iden);
    }; 
    
    const handleChangeFechaNacimiento = (event) => {
        let iden=event.target.value;
        setFechaNacimiento(iden);
    }; 
    const handleChangeNombre = (event) => {
        let iden=event.target.value;
        setNombre(iden);
    }; 
    const handleChangeApellido1 = (event) => {
        let iden=event.target.value;
        setApellido1(iden);
    }; 
    const handleChangeApellido2 = (event) => {
        let iden=event.target.value;
        setApellido2(iden);
    }; 
    const handleChangeIdentificacion = (event) => {
        let iden=event.target.value;
        if(tipoDocumento == "1"){
            let labelRut = replaceAll(iden, ['.', '-'], '');
            if (
                labelRut.length === 0 ||
                labelRut.length === 1 ||
                /^[0-9]+[0-9kK]{1}$/.test(labelRut)
                ) {
                if (!isNaN(labelRut) || labelRut.substr(-1) === 'k') {
                    if (labelRut.length >= 2)
                    labelRut = labelRut.slice(0, -1) + '-' + labelRut.slice(-1);
                    if (labelRut.length >= 6)
                    labelRut = labelRut.slice(0, -5) + '.' + labelRut.slice(-5);
                    if (labelRut.length >= 10)
                    labelRut = labelRut.slice(0, -9) + '.' + labelRut.slice(-9);
            
                    setIdentificacion(labelRut);
                }   
            }
        }else{
            setIdentificacion(iden);
        }
    }; 
    const handleChangeTipoDocumento = (event) => {
        let iden=event.target.value;
        setTipoDocumento(iden);
    }; 
    const handleChangeEmail = (event) => {
        let iden=event.target.value;
        setEmail(iden);
    }; 
    const handleChangeSexo = (event) => {
        let iden=event.target.value;
        setSexo(iden);
    }; 
    const handleChangeConfirmarEmail = (event) => {
        let iden=event.target.value;
        setConfirmarEmail(iden);               
        if(email != iden){
            setErrEmail("El email ingresado no coincide con el del campo Email");
        }else{
            setErrEmail("");
        }
    }; 
    const handleChangeDireccion = (event) => {
        let iden=event.target.value;
        setDireccion(iden);
    }; 
    const handleChangeNombresEmergencia = (event) => {
        let iden=event.target.value;        
        setNombresEmergencia(iden);
    }; 
    const handleChangeApellidosEmergencia = (event) => {
        let iden=event.target.value;
        setApellidosEmergencia(iden);
    }; 
    const handleChangeTelefonoEmergencia = (event) => {
        let iden=event.target.value;
        setTelefonoEmergencia(iden);
    }; 
    const handleChangeMismosDatos = (event)=>{
        setMismosDatos(event.target.checked+"");
    }
    const handleChangeMismoEmail = (event)=>{
        setmismosDatosEmail(event.target.checked+"");
    }
    
    const phoneValidation = (telefono) => {
        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;    
        return !(!telefono || regex.test(telefono) === false);
    }  
    const documentoValidation = (documento) => {
        const regex = /[&\/\\#,+()$~%.'":*?<>{}]/g;
        return true;
        // if(tipoDocumento!="1")
        //     return !(!documento || regex.test(documento) === true);
        // else return true;
    }  
    // const [textValue, setTextValue] = useState<string>(props.pasajero.nombre);
    const {
        // register,
        // handleSubmit,
        // reset,
        // getValues,
        // setValue, 
        control,
        // formState,
        // formState: { isSubmitSuccessful }
      } = useForm();
      
      const resetForm = () =>{
        var iden ="";
        // setTelefonoEmergencia(iden);
        // setApellidosEmergencia(iden);
        // setNombresEmergencia(iden);
        // setDireccion(iden);
        setEmail(iden);
        setSexo(iden);
        setConfirmarEmail(iden);
        setTipoDocumento(iden);
        setIdentificacion(iden);
        setApellido1(iden);
        setApellido2(iden);
        setNombre(iden);
        setFechaNacimiento(iden);        
        // setSelectedValueCiudad(iden);
        // setSelectedValueEstado(iden);
        // setSelectedValuePais(iden); 
      }
      //SECCION VALIDAR RUT TOMADO DE FORM VENTAS
      const [errRut, setErrRut] = useState({rut: null});
      const dv = T => {
        var M = 0,
          S = 1;
        for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : 'k';
      };
      const validateRut = () => {
        const rutCompleto = replaceAll(identificacion, ['.'], '');
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    
        const tmp = rutCompleto.split('-');
        let digv = tmp[1];
        const rut = tmp[0];
        if (digv == 'K') digv = 'k';
    
        return dv(rut) == digv;
      };
      const validationFormRut = () => {
        const {rut} = errRut;
        const err = {
          rut: tipoDocumento=="1" ? !validateRut() ? 'Ingresa un Rut válido' : '' : '',
        };
        setErrRut(err);
        return rut;
      };

      const handleValidation = () => {
        let rut = validationFormRut();
        if(tipoDocumento=="1"){
            if (rut !== '') {
            return false;
            } else {
            return true;
            }
        }else{
            return true;
        }
      };

      const onCustomSubmit = () => {
        const evento=new Event('submit', { bubbles: true });
        onTrigger(evento);
      };
      React.useImperativeHandle(ref, () => ({
        onCustomSubmit: onCustomSubmit
      }));
      //FIN DE SECCION

    return (        
        <form onSubmit={onTrigger} id="formularioIngresar">     
            <Alert severity="info">
                {  props.indiceMostrar === 1 ? "Ingresa los datos del pasajero titular" : 
                "Ingresa los datos del pasajero #" + props.indiceMostrar 
                }    
            </Alert>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Controller
                            render={({ field }) => (<TextField {...field} 
                                variant="outlined"
                                label="Nombre"
                                fullWidth
                                required                            
                                value={nombre}
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChangeNombre}   
                                id="nombre">
                                </TextField> )}                        
                            name="nombre" 
                                
                            control={control}                      
                            rules={{ required: true }}
                        >
                        </Controller>                   
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Apellido Paterno"
                            fullWidth 
                            required  
                            inputProps={{ maxLength: 60 }}
                            value={apellido1}
                            onChange={handleChangeApellido1}   
                            id="apellido1">
                            </TextField> )}                        
                        name="apellido1" 
                            
                        control={control}                      
                        rules={{ required: true }}
                    >
                    </Controller>
                
                </Grid> 
                <Grid item xs={12} sm={4}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Apellido Materno"
                            fullWidth    
                            required                           
                            inputProps={{ maxLength: 60 }}
                            value={apellido2}
                            onChange={handleChangeApellido2}   
                            id="apellido2">
                            </TextField> )}                        
                        name="apellido2" 
                            
                        control={control}       
                        // register="apellido"                  
                        rules={{ required: true }}
                    >
                    </Controller>
                
                </Grid>    
                <Grid item xs={12} sm={4}>                    
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Sexo"
                            fullWidth                     
                            select                                
                            required   
                            onChange={handleChangeSexo}
                            value={sexo}
                            id="sexo">
                                <MenuItem value={""} >
                                {"Seleccione su sexo"}
                                </MenuItem> 
                                <MenuItem value={"M"}>
                                    {"Masculino"}
                                </MenuItem>    
                                <MenuItem value={"F"}>
                                    {"Femenino"}
                                </MenuItem>  
                                {/* <MenuItem value={"N"}>
                                    {"No Definido"}
                                </MenuItem>           */}
                            </TextField> )}                        
                        name="sexo"                                          
                        control={control}                        
                        rules={{ required: true }}
                        defaultValue={props.pasajero.sexo} 
                    />
                    {/* <Controller
                        render={({ field }) => (<TextField {...field }                            
                            variant="outlined"
                            label="Sexo"
                            fullWidth
                            id="sexo"
                            required                            
                            type="text"                            
                            onChange={handleChangeSexo}
                            value={sexo}
                             />)}                        
                        name="sexo"  
                        placeholder=""                                                
                        control={control}                         
                        rules={{ required: true }}
                    /> */}
                </Grid>      
                <Grid item xs={12} sm={4}>                    
                
                    <Controller
                        render={({ field }) => (<TextField {...field }                            
                            variant="outlined"
                            label="Fecha de nacimiento"
                            fullWidth
                            id="fechanacimiento"
                            required                            
                            type="date"                            
                            onChange={handleChangeFechaNacimiento}
                            value={fechaNacimiento}
                            // maxDate={moment()}
                            InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{inputProps: { min: moment().subtract(69,"year").format('YYYY-MM-DD'), max: props.indiceMostrar === 1 ?  moment().subtract(18,"year").format('YYYY-MM-DD') :  moment().format('YYYY-MM-DD')
                                }
                                // ,
                                // endAdornment: { adorno }
                                }}
                             />)}                        
                        name="fechanacimiento"  
                        
                        placeholder=""                                                
                        control={control}                         
                        rules={{ required: true }}
                        // defaultValue={props.pasajero.nombre}
                    />
                   
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Nacionalidad"
                            fullWidth                     
                            select    
                            required          
                            onChange={handleChange}
                            value={selectedValuePais}      
                            id="pais">
                                <MenuItem value={""} >
                                {"Seleccione su nacionalidad"}
                                </MenuItem>                                    
                                {Paises.data!==undefined && Array.isArray(Paises.data)                  
                                    ? Paises.data.map((aux, index) => (                                     
                                    <MenuItem key={index} value={aux.paisId} >
                                    {aux.nombre}
                                    </MenuItem>                                    
                                    )):
                                    paises.map((option,index) => (
                                        <MenuItem key={index} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }   
                            </TextField> )}                        
                        name="pais"                                              
                        control={control}
                        
                        // onChange={handleChange}                        
                        rules={{ required: true }}
                        
                        // defaultValue={props.pasajero.pais}
                    >
                    </Controller>  
                   
                {Paises.fetching && (
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                )}
                </Grid>
                <Grid item xs={12} sm={4} className="oculto">
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Tipo de documento"
                            fullWidth                     
                            select    
                            required        
                            value={tipoDocumento}
                            InputProps={{
                                readOnly: true,
                            }}                                
                            onChange={handleChangeTipoDocumento}                                                                                  
                            id="tipoDocumento">
                                {/* <MenuItem value={""} >
                                {"Seleccione el tipo de documento"}
                                </MenuItem>                                     */}
                                {TipoDocumentos.data!==undefined && Array.isArray(TipoDocumentos.data)                  
                                    ? TipoDocumentos.data.map((aux, index) => (                                     
                                    <MenuItem key={index} value={aux.tipodocumentoId} >
                                    {aux.etiqueta}
                                    </MenuItem>                                    
                                    )):
                                    tipoDocumentos.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }  
                                
                            </TextField> )}                        
                        name="tipoDocumento"                                             
                        control={control}                                              
                        // register="tipoDocumento"                                            
                        rules={{ required: true }}
                        // defaultValue={props.pasajero.tipoDocumento}
                    >
                    </Controller>
                
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Número de documento"
                            fullWidth 
                            required   
                            placeholder={Array.isArray(TipoDocumentos.data) && TipoDocumentos.data!=undefined ? 
                                 TipoDocumentos.data.find(x=>x.tipodocumentoId === tipoDocumento) != null ? TipoDocumentos.data.find(x=>x.tipodocumentoId === tipoDocumento).etiqueta
                                 : "Tipo documento" 
                                  : "Tipo documento"}
                            inputProps={{ maxLength: 20 }}                            
                            onBlur={validationFormRut}
                            onKeyUp={validationFormRut}
                            value={identificacion}
                            onChange={handleChangeIdentificacion}
                            helperText={errRut.rut ? errRut.rut : 'Ejemplo 9.999.999-9'}
                            id="identificacion">
                            </TextField> )}                        
                        name="identificacion"                          
                        
                        
                        control={control}       
                        // register="identificacion"                                    
                        rules={{ required: true }}
                        defaultValue={props.pasajero.identificacion}
                    >
                    </Controller>
                    {/* <span
                        className="float-left error-insured"
                        style={!errRut.rut ? {color: '#707070'} : {}}>
                        {errRut.rut ? errRut.rut : 'Ejemplo 9.999.999-9'}
                    </span>         */}
                </Grid>                
                <Grid item xs={12} sm={4} className={validarTitularMismosDatosEmail ? "oculto" :""}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Email"
                            fullWidth 
                            required   
                            type="email"
                            inputProps={{ 
                                maxLength: 50 
                            }}
                            
                            value={email}
                            onChange={handleChangeEmail}
                            id="email">
                            </TextField> )}                        
                        name="email"                          
                        control={control}         
                        // register="email"
                        rules={{ required: true }}
                        defaultValue={props.pasajero.email}
                    >
                    </Controller>
                
                </Grid>   
                <Grid item xs={12} sm={4} className={validarTitularMismosDatosEmail ? "oculto" :""}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Confirmar Email"
                            fullWidth 
                            required   
                            type="email"
                            inputProps={{ 
                                maxLength: 50 
                            }}
                            helperText={errEmail ? errEmail : ''}
                            value={confirmaremail}
                            onChange={handleChangeConfirmarEmail}
                            id="confirmaremail">
                            </TextField> )}                        
                        name="confirmaremail"                          
                        control={control}         
                        // register="email"
                        rules={{ required: true }}
                        defaultValue={props.pasajero.confirmaremail}
                    >
                    </Controller>
                
                </Grid> 
                {props.indiceMostrar === 1 &&
                    <Grid item xs={12} sm={4}
                        className={                            
                            props.maxNumPasajeros=="1" ? "oculto": ""
                        }>
                        <FormGroup>
                            <Controller
                                render={({ field }) => (
                                    <FormControlLabel 
                                    
                                    control={<Checkbox {...field} 
                                    value={props.maxNumPasajeros==1 ? true : mismosDatosEmail}
                                    
                                    onChange={handleChangeMismoEmail}                                    
                                    />} 
                                    label="Mi email será usado para los otros pasajeros" 
                                    /> )}                        
                                name="mismosDatosEmail"                                  
                                control={control}         
                            >
                            </Controller>                           
                        </FormGroup>
                    </Grid>
                }  
                <Grid item xs={12} sm={4}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Región"
                            fullWidth                     
                            select                                
                            required   
                            onChange={handleChange2}
                            value={selectedValueEstado}
                            id="provincia">
                                <MenuItem value={""} >
                                {"Seleccione su región"}
                                </MenuItem>                 
                                {Estados.data!==undefined && Array.isArray(Estados.data)                                      
                                    ? Estados.data.map((aux, index) => (                         
                                    <MenuItem key={index} value={aux.estadoId}>
                                    {aux.nombre}
                                    </MenuItem>                                    
                                    )):
                                    estados.map((option,index) => (
                                        <MenuItem key={index} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }  
                            </TextField> )}                        
                        name="provincia"    
                        // register="provincia"                                      
                        control={control}                                                       
                        // onChange={handleChange2}                     
                        rules={{ required: true }}
                        defaultValue={props.pasajero.provincia} 
                    >
                    </Controller>
                
                {Estados.fetching && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Comuna"
                            fullWidth                     
                            select    
                            required                               
                            onChange={handleChange3}
                            value={selectedValueCiudad}
                            id="ciudad">
                                <MenuItem value={""} >
                                {"Seleccione su comuna"}
                                </MenuItem>                 
                                {Ciudades.data!==undefined  && Array.isArray(Ciudades.data)                                    
                                    ? Ciudades.data.map((aux, index) => ( 
                                    <MenuItem key={index} value={aux.ciudadId}>
                                    {aux.nombre}
                                    </MenuItem>
                                    
                                    )):
                                    ciudades.map((option,index) => (
                                        <MenuItem key={index} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }    
                            </TextField> )}                        
                        name="ciudad"                          
                        control={control}  
                        // register="ciudad"        
                        // onChange={handleChange3}                   
                        rules={{ required: true }}
                        defaultValue={props.pasajero.ciudad}
                    >
                    </Controller>
               
                {Ciudades.fetching && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    )}
                </Grid>
                  
                <Grid item xs={12}>
                    {/* { validarTitularMismosDatos ?
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Dirección"
                            fullWidth 
                            required   
                            InputLabelProps={{
                                shrink: true,
                              }}
                            disabled
                            inputProps={{ maxLength: 50 }}
                            value={direccion}
                            placeholder="Mz calle 321, Depto 53"
                            onChange={handleChangeDireccion}
                            id="direccion">
                            </TextField> )}                        
                        name="direccion"  
                        
                        control={control}      
                        // register="direccion"                           
                        rules={{ required: true }}
                        defaultValue={props.pasajero.direccion}    
                    >
                    </Controller>
                    : */}
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Dirección"
                            fullWidth 
                            required   
                            InputLabelProps={{
                                shrink: true,
                              }}
                            inputProps={{ maxLength: 50 }}
                            value={direccion}
                            placeholder="Mz calle 321, Depto 53"
                            onChange={handleChangeDireccion}
                            id="direccion">
                            </TextField> )}                        
                        name="direccion"  
                        
                        control={control}      
                        // register="direccion"                           
                        rules={{ required: true }}
                        defaultValue={props.pasajero.direccion}    
                    >
                    </Controller>
                    {/* } */}
                </Grid>  
                {/* {props.pasajero.indice===1 &&          */}
                <React.Fragment>
                <Grid item xs={12} sm={12} align="left" className={validarTitularMismosDatos ? "oculto" :""}>
                    <Typography variant="h6" gutterBottom className="text-left">
                    Contacto de emergencia
                    </Typography>    
                </Grid>
                <Grid item xs={12} sm={6} className={validarTitularMismosDatos ? "oculto" :""}>
                    { validarTitularMismosDatos ?
                        <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Nombre"
                            fullWidth 
                            required   
                            disabled
                            inputProps={{ maxLength: 60 }}
                            value={nombresEmergencia}
                            onChange={handleChangeNombresEmergencia}
                            id="nombreContacto">
                            </TextField> )}                        
                        name="nombreContacto"                          
                        control={control}     
                        // register="nombreContacto"                     
                        rules={{ required: true }}
                        defaultValue={props.pasajero.nombresEmergencia}
                    >
                    </Controller>
                    :
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Nombre"
                            fullWidth 
                            required   
                            inputProps={{ maxLength: 60 }}
                            value={nombresEmergencia}
                            onChange={handleChangeNombresEmergencia}
                            id="nombreContacto">
                            </TextField> )}                        
                        name="nombreContacto"                          
                        control={control}     
                        // register="nombreContacto"                     
                        rules={{ required: true }}
                        defaultValue={props.pasajero.nombresEmergencia}
                    >
                    </Controller>
                    }
                    
                    
                </Grid>
                <Grid item xs={12} sm={6} className={validarTitularMismosDatos ? "oculto" :""}>
                    { validarTitularMismosDatos ?
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Apellido"
                            fullWidth 
                            required   
                            disabled 
                            inputProps={{ maxLength: 60 }}
                            value={apellidosEmergencia}
                            onChange={handleChangeApellidosEmergencia}
                            id="apellidoContacto">
                            </TextField> )}                        
                        name="apellidoContacto"                          
                        control={control}           
                        // register="apellidosEmergencia"                                   
                        rules={{ required: true }}
                        defaultValue={props.pasajero.apellidosEmergencia}
                    >
                    </Controller>
                   :
                   <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Apellido"
                            fullWidth 
                            required   
                            inputProps={{ maxLength: 60 }}
                            value={apellidosEmergencia}
                            onChange={handleChangeApellidosEmergencia}
                            id="apellidoContacto">
                            </TextField> )}                        
                        name="apellidoContacto"                          
                        control={control}           
                        // register="apellidosEmergencia"                                   
                        rules={{ required: true }}
                        defaultValue={props.pasajero.apellidosEmergencia}
                    >
                    </Controller>
                    }
                </Grid>
                <Grid item xs={12} sm={6} className={validarTitularMismosDatos ? "oculto" :""}>
                    <label className='labelCodigoPais labelCodigoPaisForm text-center'>
                    +56
                    </label>
                    { validarTitularMismosDatos ?
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Teléfono"
                            style={{width:"82%", borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                            required   
                            type="phone"
                            placeholder="9111111"
                            disabled 
                            inputProps={{ minLength:9, maxLength: 9 }}
                            value={telefonoEmergencia}
                            // className='celText'
                            onChange={handleChangeTelefonoEmergencia}
                            id="telefonoContacto">
                            </TextField> )}                        
                        name="telefonoContacto"                          
                        control={control}       
                        // register="telefonoEmergencia"                                                     
                        rules={{ required: true }}
                        defaultValue={props.pasajero.telefonoEmergencia}
                    >
                    </Controller>
                    :
                    <Controller
                        render={({ field }) => (<TextField {...field} 
                            variant="outlined"
                            label="Teléfono"
                            style={{width:"82%", borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                            required   
                            type="phone"
                            placeholder="9111111"
                            inputProps={{ minLength:9, maxLength: 9 }}
                            value={telefonoEmergencia}
                            // className='celText'
                            onChange={handleChangeTelefonoEmergencia}
                            id="telefonoContacto">
                            </TextField> )}                        
                        name="telefonoContacto"                          
                        control={control}       
                        // register="telefonoEmergencia"                                                     
                        rules={{ required: true }}
                        defaultValue={props.pasajero.telefonoEmergencia}
                    >
                    </Controller>        
                    }
                </Grid>
                </React.Fragment>      
                {/* } */}
                {props.indiceMostrar === 1 &&
                    <Grid item xs={12} sm={12}
                        className={                            
                            props.maxNumPasajeros=="1" ? "oculto": ""
                        }>
                        <FormGroup>
                            <Controller
                                render={({ field }) => (
                                    <FormControlLabel 
                                    
                                    control={<Checkbox {...field} 
                                    value={props.maxNumPasajeros==1 ? true : mismosDatos}
                                    
                                    onChange={handleChangeMismosDatos}                                    
                                    />} 
                                    label="Mis datos de contacto y dirección son los mismos para los otros pasajeros" 
                                    /> )}                        
                                name="mismosDatos"                                  
                                control={control}         
                            >
                            </Controller>                           
                        </FormGroup>
                    </Grid>
                }      
                {error!=="" &&
                    <Grid item xs={12} align="center"> 
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                }
                {infoPrevious.numPersonas !== 1 && 
                    <Grid item xs={12} align="center">                   
                        <Button variant="contained" type="submit">Agregar pasajero</Button>                                        
                    </Grid>
                }                
            </Grid>            
        </form>
    );
  
});
export default PasajeroForm;
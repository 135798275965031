import axios from "axios";
import { getRecaptchaToken } from "./RecaptchaToken";
import usePasajerosStore from "../store/usePasajerosStore";

export async function sendProcesoCompra({data}) {
  const { tjw } = usePasajerosStore.getState(); 
  const recaptchaToken = await getRecaptchaToken();
  return await axios.post(`${process.env.REACT_APP_URI_SERVER_LOCAL}/api/General/Proceso/ProcesarCompra`,
    JSON.stringify(data),
    {
      headers: {
        Authorization: "Bearer " + tjw,
        'Content-Type':"application/json",
        'Recaptcha-Token': recaptchaToken, 
      }
    }
  )
}

export async function procesarCertificado(token) {
  const recaptchaToken = await getRecaptchaToken();
  const { setTjw } = usePasajerosStore.getState();
  
  setTjw(token);
  return await axios.post(`${process.env.REACT_APP_URI_SERVER_LOCAL}/api/General/Proceso/ProcesarCertificado`,
    null,
    {
      headers: {
        Authorization: "Bearer " + token,
        'Content-Type':"application/json",
        'Recaptcha-Token': recaptchaToken, 
      }
    }
  )
}



import logo from '../../imagenes/Formulario/logo-banco-estado.png'
import React, { useEffect, useRef, useState, useCallback } from "react";
import {useHistory, useLocation} from 'react-router';
import { Link } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './AddressForm';
import Grid from '@mui/material/Grid';
import PaymentForm from './PaymentForm';
import Review from './Review';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Moment from 'moment';
import '../../Styles/formulario.css'
import Footer from './footer'
import Alert from '@mui/material/Alert';
import 'moment/locale/es';
import * as apiConfirm from "../transbank/confirm";
import Swal from 'sweetalert2';
import { sendProcesoCompra } from '../../sagas/ProcesoCompra';
import ReactGA from "react-ga4";
import usePasajerosStore from "../../store/usePasajerosStore";

const steps = ['Pasajeros','Ingreso datos pasajeros', 'Resumen de compra','Confirmación contratación'];

const handleTabClose = () => { 
  var pasoApagar = localStorage.getItem("pasoApagar");
 };
 function DividirCadena1(nombreSeguro,n) {
  let arrayDeCadena = nombreSeguro.split(" ");
  let nombre = arrayDeCadena[n];
  return nombre;
}
window.addEventListener('beforeunload', handleTabClose,true);

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#EE811E',
      contrastText: 'rgba(251,248,248,0.87)',
    },
    secondary: {
      main: '#f9cb7f',
      contrastText: 'rgba(238,129,30,1)',
    },
    
    warning: {
      main: '#fdf4e2',
    },
  },  
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});
const showLoading = function(titulo) {
  Swal.fire({
    title: titulo,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton:false,
    showCancelButton: false,
    buttons:false,
    showLoaderOnConfirm: true,
    didOpen: () => {
    Swal.showLoading();
    }
  });
  };
const Checkout = () => {
  const addressFormRef = useRef(null);
  const getDatosPasajeros = usePasajerosStore(
    (state) => state.getDatosPasajeros
  );
  const handleAgregarPasajero = () => {
    setValidacion(true);
    if (addressFormRef.current) {
      addressFormRef.current.invokeAgregarPasajero();
      handleNext();
    }
  };
  localStorage.setItem("pasoApagar","0");
  const infoPrevious = JSON.parse(localStorage.getItem("datosForm"));
  const [listaPasajeros,setListaPasajeros] = useState(getDatosPasajeros());
  const [datosPaymentForm,setDatosPaymentForm] = useState(null);
  const handleCallback = (childData) =>{
    setDatosPaymentForm(childData);
  }
const validarMaxNumPasajeros = ()=>{
  var pasajeross = getDatosPasajeros();
  setListaPasajeros(getDatosPasajeros());
  if (pasajeross.length==undefined) pasajeross=new Array();
  setTotalPasajerosValido(pasajeross.length < infoPrevious.numPersonas);
  setValidacion(pasajeross.length >= infoPrevious.numPersonas);
}
function getStepContent(step) {
  switch (step) {
    case 0:
      return "";
    case 1:
      return <AddressForm ref={addressFormRef} MaxNumPasajeros = {infoPrevious.numPersonas} parentCallback = {validarMaxNumPasajeros}/>;
    case 2:
      return <PaymentForm parentCallback = {handleCallback} />;
    case 3:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}
  const history = useHistory();
  const location = useLocation();
  const [newCustomer, setNewCustomer] =  React.useState(null);
  const [estadoCompra, setEstadoCompra] =  React.useState(false);
  const [totalPasajerosValido, setTotalPasajerosValido] =  React.useState(listaPasajeros!=null && infoPrevious!=null ? listaPasajeros.length < infoPrevious.numPersonas : true);
  //Respuesta de transbank
  const [mensaje, setMensaje] = React.useState("");
  const respuestaPay = localStorage.getItem("respuestaPay");
  const { initializeStore } = usePasajerosStore();
  
  useEffect(() => {
    initializeStore();
  }, [initializeStore]);  
  useEffect(() => {  
    ReactGA.event({ action:"Formulario Inicial", category: "Formulario" , label: infoPrevious.plan.nombreSeguro });  
    async function confirmarCompra(){
      let bo = localStorage.getItem("buyOrder");
      const confirmar = await apiConfirm.verificarCompra(bo==null || bo== undefined || bo < 0 ? 0 : bo);
      if(confirmar.estado == 0){
        setMensaje(confirmar.mensaje);
        setEstadoCompra(true);
        setActiveStep(3);
      }else{
        setMensaje(confirmar.mensaje);
      }
    }
    if (location.state) {
      // @ts-ignore
      const {newCustomer} = location.state;
      setNewCustomer({...newCustomer});
      localStorage.setItem("paymentFormData",
      JSON.stringify({      
        errores:[],
        check1:false,
        check2:false,
        celular:"",
        codigoSms:""        
      }));
    } 
    else if(respuestaPay!=undefined && respuestaPay!=""){
      confirmarCompra();
    }
  }, [location]);
  
  const titleRef = useRef();
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };
    validarMaxNumPasajeros();
    const timeoutId = setTimeout(scrollToTop, 500);
    return () => clearTimeout(timeoutId);
  }, []);
  var mensajeError="";
  const [activeStep, setActiveStep] = React.useState(1);
  const [validacion, setValidacion] = React.useState(true);

  const [url,setUrl] = useState("");
  const [token,setToken] = useState("");
  const formRef = React.useRef(null);
  const irTransbank = async (orden)=>{
    var tokenRecibido = "";
    try {
      var form = {valor:infoPrevious.plan.valorSeguro, order: orden};
      const registro = await apiConfirm.calltransbank(form);
      setUrl(registro.url);
      setToken(registro.token);
      tokenRecibido = registro.token;      
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
    } finally {
      if(tokenRecibido!="" && tokenRecibido!=undefined && tokenRecibido!=null){
        localStorage.setItem("buyOrder", orden);
        ReactGA.event({ action:"Redireccion Transbank", category: "Transbank" });  
        formRef.current.submit();        
      }else{
        Swal.fire({
          title: 'No se pudo conectar al servicio de Pago.',
          icon:"error"
        });
      }      
    }
    return;
  }
  const validarFormulario = () =>{
    var datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    if(!datosPayment.check1) datosPayment.errores.push("Tiene que marcar la opcion 'Acepto las Condiciones de Seguro'");
    if(!datosPayment.check2) datosPayment.errores.push("Tiene que marcar la opcion 'Acepto las Condiciones de Pago'");
    if(datosPayment.celular.trim()==="") datosPayment.errores.push("Tiene que ingresar el numero de celular");
    if(datosPayment.codigoSms.trim()==="") datosPayment.errores.push("Tiene que ingresar el codigo de seguridad enviado a su celular");
    if(datosPayment.errores.length>0){
      var html = "<ol>";
      for(var i=0;i<datosPayment.errores.length;i++)
        html+="<li>"+datosPayment.errores[i]+"</li>";
      html += "<ol>";
      Swal.fire({
          title: "ALERTA",
          html: html,
          icon:"error",                
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText:
              'Cerrar',
          confirmButtonAriaLabel: 'Ok'
      });
    }
    return datosPayment.errores.length === 0 ? true : false;
  }
  const showLoading = function(titulo) {
    Swal.fire({
      title: titulo,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton:false,
      showCancelButton: false,
      buttons:false,
      showLoaderOnConfirm: true,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  };
  const mapDatos = () =>{
    var datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    var infoPasajeros = getDatosPasajeros();    
    var datos = {
      Edadmayor:infoPrevious.edadMaxima,
      Fechaida: Moment(infoPrevious.minDate).format('YYYY-MM-DD'),
      Fecharegreso:Moment(infoPrevious.maxDate).format('YYYY-MM-DD'),
      Tipodestino:infoPrevious.plan.idTIpoSeguro === 601 ? "N": "I",
      PlanId:infoPrevious.plan.idSeguro,
      Aceptocondicionesseguro:datosPayment.check1,
      Aceptocondicionespago: datosPayment.check2,
      SesionanonimaId:0,
      CapitalAsegurado:"",
      CelularUsado:"56"+datosPayment.celular,
      codigoSms:datosPayment.codigoSms,
      ValorPrima: infoPrevious.plan.valorPrima+"",
      Pasajeros:[]
    }
    for(var i=0;i<infoPasajeros.length;i++){
      datos.Pasajeros.push({
        Nombre:infoPasajeros[i].nombre,
        Apellido1:infoPasajeros[i].apellido1,
        Apellido2:infoPasajeros[i].apellido2,
        TipodocumentoId:infoPasajeros[i].tipoDocumento,
        Documento:infoPasajeros[i].identificacion,
        Direccion:infoPasajeros[i].direccion,
        PaisId:infoPasajeros[i].pais,
        Sexo:infoPasajeros[i].sexo,
        EstadoId:infoPasajeros[i].provincia,
        CiudadId:infoPasajeros[i].ciudad,
        Fechanacimiento: infoPasajeros[i].fechanacimiento,//formDate,
        Email:infoPasajeros[i].email,
        Contacto:[{
            Nombre:infoPasajeros[i].nombresEmergencia,
            Apellido:infoPasajeros[i].apellidosEmergencia,
            Telefono:infoPasajeros[i].telefonoEmergencia
        }]
      });      
    }
    return datos;
  }
  const handleNext = async () => {    
    var infoPasajeros = getDatosPasajeros();
    setValidacion(false);
    localStorage.setItem("pasoApagar","0");    
    if(Array.isArray(infoPasajeros)){
      if(infoPasajeros.length >= infoPrevious.numPersonas){
        setValidacion(true);
        if(activeStep===2){
          ReactGA.event({ action:"Resumen Formulario", category: "Formulario"});
          var infoSend = mapDatos();
          try{
            if(validarFormulario()) {
              showLoading("Guardando los datos");
              const respuesta = await sendProcesoCompra({data: infoSend});
              Swal.close();
              if(respuesta.status==200){
                if(respuesta.data.estado===0){
                  localStorage.setItem("pasoApagar","1");
                  window.removeEventListener('beforeunload', handleTabClose, true);
                  Swal.fire({
                    title: respuesta.data.mensaje + '. Redireccionando al servicio de pago',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton:false,
                    showCancelButton: false,
                    buttons:false,
                    showLoaderOnConfirm: true,
                    didOpen: () => {
                      Swal.showLoading();
                    }
                  }); 
                  setTimeout(()=>{
                    irTransbank(respuesta.data.item);
                  }, 3000);                  
                  
                } else{
                  var html = "<label>"+respuesta.data.mensaje+"</label>";
                  if(Array.isArray(respuesta.data.item)){
                    if(respuesta.data.item.length>0){
                      html += "<ol>";
                      for(var i=0;i<respuesta.data.item.length;i++)
                        html+="<li>"+respuesta.data.item[i]+"</li>";
                      html += "<ol>";                  
                    }
                  }
                  Swal.fire({
                      title: "ALERTA",
                      html: html,
                      icon:"error",                
                      showCloseButton: true,
                      showCancelButton: false,
                      confirmButtonText:
                          'Cerrar',
                      confirmButtonAriaLabel: 'Ok'
                  });
                }
              }
            }
          }catch(error){
            Swal.close();
            Swal.fire({
              title: 'No hay conexión con el servidor del sistema.',
              icon:"error"
            });
          }
        }else{
          if(activeStep===1){
            ReactGA.event({ action:"Formulario Pasajeros Completo", category: "Formulario"});
          }  
          window.removeEventListener('beforeunload', handleTabClose, true);      
          setActiveStep(activeStep + 1);                 
        }        
      }
    }    
  };

  const handleBack = () => {    
    if(activeStep===2)
      setActiveStep(activeStep - 1);
    else{
      setInfoPrevious();
      history.push("/");
    }
  };
  const setInfoPrevious=()=>{     
    localStorage.clear();   
  };
  const validarEntradas = ()=>{
    var fechaActual = Moment();
    fechaActual.set({
      hour:   0,
      minute: 0,
      second: 0
    });
    var fechaAunAnio = Moment().add(1,"year").subtract(1,"days");
    fechaAunAnio.set({
      hour:   0,
      minute: 0,
      second: 0
    });
    try{
      if(infoPrevious==undefined){
        mensajeError="no ha completado el formulario para que seleccione su plan";
        return false;
      }else if(infoPrevious.plan==undefined || infoPrevious.plan==null){
        mensajeError="no ha seleccionado ningún plan";
        return false;
      }else if(infoPrevious.minDate==undefined  || infoPrevious.minDate==null){
        mensajeError="no ha seleccionado la fecha de viaje";
        return false;
      }
      // else if(Moment(infoPrevious.minDate).toDate()>=fechaActual.toDate() ){
      //   mensajeError="la fecha de viaje seleccionada no es válida";
      //   return false;
      // }
      else if(infoPrevious.maxDate==undefined || infoPrevious.maxDate==null){
        mensajeError="no ha seleccionado la fecha de retorno";
        return false;
      }
      // else if(Moment(infoPrevious.maxDate).toDate() <= Moment(infoPrevious.minDate).toDate() ){
      //   mensajeError="la fecha de retorno seleccionada no puede ser menor o igual a la fecha de viaje";
      //   return false;
      // }
      // else if(Moment(infoPrevious.maxDate).toDate() >= fechaAunAnio.toDate()){
      //   mensajeError="la fecha de retorno seleccionada no puede ser mayor o igual a 1 año";
      //   return false;
      // }
      else if(infoPrevious.edadMaxima==undefined || infoPrevious.edadMaxima==null){
        mensajeError="la edad del pasajero mayor no es válida";
        return false;
      }else if(infoPrevious.edadMaxima<18 || infoPrevious.edadMaxima>69){
        mensajeError="la edad del pasajero mayor no es válida";
        return false;
      }
    }catch(error){
      mensajeError="los datos del paso 1 no son los esperados";
      return false;
    }
    if(infoPrevious.numPersonas < 0) infoPrevious.numPersonas=1;

    return true;
  }
  Moment.locale('es'); 
  return (
    <ThemeProvider theme={theme}>
      <form style={{ display: "none" }}
        action={url}
        method="post"
        ref={formRef}
      >
        <input type="text" name="TBK_TOKEN" value={token} />
      </form>
      {/* <CssBaseline /> */}
      <AppBar
        ref={titleRef}
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          backgroundColor:'white'
          // borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
        
      >
        <Toolbar align="center">
          {/* <Typography variant="h6" color="inherit" noWrap>
            Company name
          </Typography> */}
          <p className='text-center col'>
            <img src={logo} alt="Banco del Estado" className='logo'/>
          </p>
        </Toolbar>
      </AppBar>
      <Paper elevation={0} square className="wellGray2" style={{background:"#E7E6E6"}}>
      </Paper>
      <Container component="main" maxWidth="md" sx={{ mb: 6 }} id="padreFormulario">             
        <Paper variant="outlined" id="formularioPasajero" sx={{ borderTop: 5,borderTopColor: '#EE811E', borderBottom:45, borderBottomColor:"#fdf4e2",  my: { xs: 0, md: 0 }, px: { xs: 1, md: 3 }, py: { xs: 1, md: 1 } }} className="franja">
          <Paper elevation={0} sx={{ borderBottom: 3, borderBottomColor: '#E7E6E6'}}>
            <Grid container spacing={0}>  
              <Grid item sm={12}>
                <Button style={{float:"right"}} className="text-right bg-gray-text no-decorator" 
                onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                <KeyboardReturnIcon></KeyboardReturnIcon>  Volver</Button>                
              </Grid>              
            </Grid>
          </Paper> 
          {validarEntradas() ?
          <React.Fragment>
            <Paper elevation={1} square className='mt-2 p-2' id="seccion_resumen">             
              <Grid container spacing={0}>  
                <Grid item sm={12} align="center">
                  <label>                   
                    <Typography className='ml-1' align="center" component="b">
                    {/* {infoPrevious.plan.nombreSeguro}<br/> */}
                    
                    {DividirCadena1(infoPrevious.plan.nombreSeguro,0)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,1)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,2).split('USD')}<br/>
                    {DividirCadena1(infoPrevious.plan.nombreSeguro,2).split('Nacional')}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,3)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,4)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,5)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,6)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,7)}<br/>
                    {infoPrevious.plan.clinica1}
                    </Typography>
                  </label>
                </Grid>        
                <Grid item sm={2} align="center"></Grid>
                <Grid item sm={4} align="center">
                  <Grid item sm={12} align="left">
                    <label><FlightTakeoffIcon style={{ color: "#EE811E" }}></FlightTakeoffIcon>
                    Fecha de Salida:</label>
                    <Typography className='ml-1' component="b" align="center" style={{color:"black"}}>
                    {Moment(infoPrevious.minDate).format('DD MMM YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} align="left">
                    <label>
                    <AirlineSeatReclineNormalIcon style={{ color: "#EE811E" }}></AirlineSeatReclineNormalIcon>
                      Número de personas:
                      <Typography className='ml-1' align="center" component="b" style={{color:"black"}}>
                      {infoPrevious.numPersonas}
                      </Typography>
                    </label>
                  </Grid>
                </Grid>
                <Grid item sm={1} align="center"></Grid>
                <Grid item sm={4} align="left">
                  <Grid item sm={12}>
                    <label><FlightLandIcon style={{ color: "#EE811E" }}></FlightLandIcon>
                      Fecha de Regreso:
                      <Typography className='ml-1' align="center" component="b" style={{color:"black"}}>
                      {Moment(infoPrevious.maxDate).format('DD MMM YYYY')}
                      </Typography>
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <label>
                    <PermContactCalendarIcon style={{ color: "#EE811E" }}></PermContactCalendarIcon>
                      Edad del Mayor:
                      <Typography className='ml-1' align="center" component="b" style={{color:"black"}}>
                      {infoPrevious.edadMaxima}
                      </Typography>
                    </label>
                  </Grid>
                </Grid>
                <Grid item sm={1} align="center"></Grid>
                <Grid item sm={2} align="center"></Grid>
                {
                // estadoCompra && activeStep==3
                Array.isArray(listaPasajeros)
                 && listaPasajeros.length > 0 &&
                <Grid item sm={10} align="left">
                  <label>
                    <MarkEmailReadIcon style={{ color: "#EE811E" }}></MarkEmailReadIcon>
                      Correo electrónico para envío de certificado de cobertura: 
                      <Typography className='ml-1' align="center" component="b" style={{color:"black"}}>
                      {Array.isArray(listaPasajeros) ? listaPasajeros.length > 0 ? listaPasajeros[0].email : "" : "" }
                      </Typography>
                    </label>
                </Grid>
                }
              </Grid>                
            </Paper>    
            {estadoCompra && activeStep == 3 &&
              <Alert severity={ estadoCompra ? "success": "error"}>{mensaje}</Alert>               
            }            
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
                <React.Fragment>
                  
                  {getStepContent(activeStep)}
                  {validacion===false &&
                    <Alert severity="info">Estimado, tiene que ingresar {infoPrevious.numPersonas} {infoPrevious.numPersonas==1 ? "persona":"personas"}.</Alert>
                  }
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {activeStep !== 0 && activeStep !== steps.length-1 &&  (//activeStep !== steps.length-1 &&
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Volver
                      </Button>
                    )}                    
                    {activeStep <= steps.length-2 && ((infoPrevious.numPersonas === 1 && !(activeStep === 1 && totalPasajerosValido)) || infoPrevious.numPersonas !== 1 || (infoPrevious.numPersonas === 1 && activeStep === steps.length - 2)) &&
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={activeStep === 1 && totalPasajerosValido }
                        sx={{ mt: 3, ml: 1 }}
                      >
                        {activeStep === steps.length - 2 ? 'Contratar' : 'Siguiente'}
                      </Button>
                    }
                    {infoPrevious.numPersonas === 1 && activeStep === 1 && totalPasajerosValido && 
                      <Button
                        variant="contained"
                        onClick={handleAgregarPasajero}
                        sx={{ mt: 3, ml: 1 }}
                      >Continuar</Button>
                    }                    
                  </Box>
                </React.Fragment>
            </React.Fragment> 
          </React.Fragment>
          :
          <React.Fragment>
            <Paper elevation={1} square className='mt-2 p-2'>             
              <Grid container spacing={0}>  
                <Grid item sm={12} align="center">
                  <p>Estimado, {mensajeError}, por favor regrese y complete el formulario.</p>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
          }
        </Paper>
      </Container>
      <Footer></Footer>
    </ThemeProvider>
    
  );
}
export default Checkout;
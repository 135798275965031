import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import  Paper  from '@mui/material/Paper';
import { FormControl, Radio, FormGroup, Button } from '@mui/material';
import webpay from '../../imagenes/Formulario/webpay.jpg';
import Moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import 'moment/locale/es';
import axios from "axios";
import PDFPago from './PDFPago';
import PDFSeguro from './PDFSeguro';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
import {useHistory, useLocation} from 'react-router';
import ReactGA from "react-ga4";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import usePasajerosStore from "../../store/usePasajerosStore";
import { getRecaptchaToken } from "../../sagas/RecaptchaToken";

Moment.locale('es'); 
function currencyFormat(num) {  
  return '$' + num.toFixed(2).replace(".",",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').split(",")[0];
}

function DividirCadena1(nombreSeguro,n) {
  let arrayDeCadena = nombreSeguro.split(" ");
  let nombre = arrayDeCadena[n];
  return nombre;
}
export default function PaymentForm(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const location = useLocation();
  const { datosPasajeros } = usePasajerosStore.getState();
  const history= useHistory();
  const infoPrevious = JSON.parse(localStorage.getItem("datosForm"));
  const infoPasajeros = datosPasajeros;
  const [showCodiciones1,setShowCodiciones1 ]= useState(false);
  const [showCodiciones2,setShowCodiciones2 ]= useState(false);
  const [codigoSms, setcodigoSms] = useState("");
  const [checkcondiciones1,setCheckcondiciones1 ]= useState(false);
  const [checkcondiciones2,setCheckcondiciones2 ]= useState(false);
  const [habCheckcondiciones1,setHabCheckcondiciones1 ]= useState(true);
  const [habCheckcondiciones2,setHabCheckcondiciones2 ]= useState(true);
  const [smsEnviado,setSmsEnviado ]= useState(true);
  const [celularUsado,setCelularUsado ]= useState("");
  const { tjw, initializeStore } = usePasajerosStore();
  const dispatch = useDispatch();
  const numDias = Moment(infoPrevious.maxDate).diff(Moment(infoPrevious.minDate),'days') + 1;
  const [celular, setCelular] = useState("");
  const [timer, setTimer] = useState(60);    
  const [contadorPedidosSMS, setContadorPedidosSMS] = useState(0);    
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

  useEffect(() => {        
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  const resetTimer = function () {
    if (timer===0) {
      setTimer(60);
    }
  };

  const handleChangeCelular = (event) => {
    let iden=event.target.value;
    setCelular(iden);
    var datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    datosPayment.celular = iden;
    localStorage.setItem("paymentFormData", JSON.stringify(datosPayment));
  }; 

  const handleChangeCodigoSms = (event) => {
    let iden=event.target.value;
    setcodigoSms(iden);
    var datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    datosPayment.codigoSms = iden;
    localStorage.setItem("paymentFormData", JSON.stringify(datosPayment));
  }; 

  const checkChange1 = () => {
    let iden=!checkcondiciones1;
    setCheckcondiciones1(iden);
    var datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    datosPayment.check1 = iden;
    localStorage.setItem("paymentFormData", JSON.stringify(datosPayment));
    if (iden) ReactGA.event({ action:"Acepta Condiciones Seguro", category: "Formulario" });
  }; 

  const checkChange2 = () => {
    let iden=!checkcondiciones2;
    setCheckcondiciones2(iden);
    var datosPayment = JSON.parse(localStorage.getItem("paymentFormData"));
    datosPayment.check2 = iden;
    localStorage.setItem("paymentFormData", JSON.stringify(datosPayment));
    if (iden) ReactGA.event({ action:"Acepta Condiciones Pago", category: "Formulario" });
  }; 

  const handleAceptar1 = () => {
    setShowCodiciones1(false);
    setHabCheckcondiciones1(false);
    if (!checkcondiciones1) checkChange1();
  };

  const handleAceptar2 = () => {
    setShowCodiciones2(false);
    setHabCheckcondiciones2(false);
    if (!checkcondiciones2) checkChange2();
  };

  const Sms = useSelector(
    (state) => state.Sms
  );
  useEffect(() => {
  }, [Sms.data]);

  const validarFormulario = (event) =>{
    var mensajes = {
      errores:[],
      check1:checkcondiciones1,
      check2:checkcondiciones2,
      celular:celularUsado,
      codigoSms:codigoSms
    };
    if(!checkcondiciones1) mensajes.errores.push("Tiene que marcar la opcion 'Acepto las Condiciones de Seguro'");
    if(!checkcondiciones2) mensajes.errores.push("Tiene que marcar la opcion 'Acepto las Condiciones de Pago'");
    if(celularUsado.trim()==="") mensajes.errores.push("Tiene que ingresar el numero de celular");
    if(codigoSms.trim()==="") mensajes.errores.push("Tiene que ingresar el codigo de seguridad enviado a su celular");
    props.parentCallback(mensajes); 
  }
  const showLoading = function(titulo) {
    Swal.fire({
      title: titulo,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton:false,
      showCancelButton: false,
      buttons:false,
      showLoaderOnConfirm: true,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  };
  return (
    
    <React.Fragment>
      <Paper elevation={1} square className='mt-2 p-3 bg-gray-100' style={{background: "#E7E6E6"}}>
        <Typography variant="h6" gutterBottom className='strong'>
        Resumen de su compra
        </Typography>
        <Grid container spacing={3} >          
          <Grid item xs={12} md={4} align="center">
            <Paper  justifycontent="center" className='wellGray' 
            style={{backgroundColor:"#F1F1F1",borderRadius:"10px",minHeight:"165px" }}>
              <Typography variant="h6" gutterBottom className='strong'>
              Resumen
              </Typography>
              <div className='row'>
              <Typography variant="span" align="center" gutterBottom className='strong'>
              {infoPasajeros.length + (infoPasajeros.length === 1? " Pasajero":" Pasajeros") }
              </Typography>
              </div>   
              <div className='row'>
              <Typography variant="p" gutterBottom className='strong'>
              { numDias + " " }
                { numDias===1 ? "día" : "días"} 
              </Typography>
              </div>           
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} align="center" justify="center">
            <Paper  justifycontent="center" className='wellGray' 
              style={{backgroundColor:"#F1F1F1",borderRadius:"10px",minHeight:"165px" }}>
                <Typography variant="h6" gutterBottom className='strong'>
                Plan
                </Typography>
                <div className='row'>
                <Typography variant="span" gutterBottom className='strong'>
                <strong>{DividirCadena1(infoPrevious.plan.nombreSeguro,0)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,1)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,2).split('USD')}</strong><br/>
                <strong>{DividirCadena1(infoPrevious.plan.nombreSeguro,2).split('Nacional')}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,3)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,4)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,5)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,6)}&nbsp;{DividirCadena1(infoPrevious.plan.nombreSeguro,7)}</strong>
                    
                </Typography>
                </div>
                <div className='row'>
                <Typography variant="span" gutterBottom className='strong'>
                <strong>{infoPrevious.plan.clinica1}</strong>
                </Typography>
                </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} id="boxPrice" align="center" justify="center" >
            <Paper  justifycontent="center" className='wellGray' 
              style={{backgroundColor:"#F1F1F1",borderRadius:"10px",minHeight:"165px" }}>
                <Typography variant="h6" gutterBottom className='strong'>
                Valor de tu seguro
                </Typography>
                <div className='col-md-12'>
                <Typography variant="span" gutterBottom className='strong'>
                Prima
                </Typography>
                </div>
                <div className='col-md-12'>
                <Typography variant="span" gutterBottom className='strong'>
                  {currencyFormat(infoPrevious.plan.valorSeguro)}
                </Typography>
                </div>
                <div className='col-md-12'>
                <Typography variant="span" gutterBottom className='strong plufPL'>
                  UF {infoPrevious.plan.valorPrima} <sup className="pluf"> * &nbsp;</sup>
                </Typography>
                </div>
                
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <br />
      <Paper elevation={0} align="center">
        <Typography variant="h6" gutterBottom className='strong'>
          Selecciona el método de pago
        </Typography>
      </Paper>
      <Paper elevation={0} square className='mt-2 p-2'>
        <Grid container spacing={3} className="mt-2">
          <Grid item xs={12} md={12} sx={{ borderBottom: 1,borderColor: 'grey.500' }} align="center">
            <FormControl style={{display:"inline-block"}}>
              <Radio checked style={{width:"fit-content"}}>          
              </Radio>
              <img src={webpay} alt="WebPay" className='logoWbe'></img>            
            </FormControl>
            
          </Grid>
          <Grid item xs={12} md={12} align="left">
            <h5>Para continuar con la emisión de tu póliza, es obligatorio que aceptes las condiciones del Seguro y de Pago, para lo cual debes revisar las condiciones de cada uno.</h5>
          </Grid>
          <Grid item xs={12} md={12} style={{alignItems: 'center'}} align="center">            
              <FormGroup style={{display:"inline-flex"}}>
                <FormControlLabel control={<Checkbox disabled={habCheckcondiciones1} id="checkcondiciones1" name="checkcondiciones1" 
                  checked={checkcondiciones1}
                  onChange={checkChange1}
                />} align="center" label={
                  <label>Acepto Condiciones de Seguro.
                    <Tooltip title="Lea las Condiciones de Seguro">
                     <button className='buttonAsLink' onClick={ ()=>{
                    setShowCodiciones1(true);
                  }}>
                    Ver condiciones
                  </button>  
                  </Tooltip>
                  </label>
                  } />
                <FormControlLabel control={<Checkbox disabled={habCheckcondiciones2} id="checkcondiciones2" name="checkcondiciones2"
                  checked={checkcondiciones2}
                  onChange={checkChange2}
                />} align="center" label={
                  <label>Acepto Condiciones de Pago.
                  <Tooltip title="Lea las Condiciones de Pago">
                    <button className='buttonAsLink' onClick={ ()=>{
                      setShowCodiciones2(true);
                    }}>
                      Ver condiciones
                    </button>  
                  </Tooltip>
                  </label>
                  } />
              </FormGroup>            
          </Grid>
          <Grid item xs={12} md={12} align="justify">
            {showCodiciones1 &&     
            <React.Fragment>
            <Alert severity="info">Por favor lee todo el documento de las condiciones del Seguro antes de aceptar.</Alert>
            <Paper  justifycontent="justify" align="justify" className='' 
            style={{backgroundColor:"#F1F1F1",borderRadius:"10px" }}
            >       
            <div id="terminos1" className="row" >
              <PDFSeguro />
            </div>
            <div align="center">
              <Button variant="contained"
                    align="center" 
                    type='button' 
                    onClick={handleAceptar1}
                    sx={{margin:"10px",verticalAlign:"top"}}>Aceptar Condiciones</Button>
              <Button variant="contained"
                    align="center" 
                    type='button' 
                    onClick={()=>{setShowCodiciones1(false);}}
                    sx={{margin:"10px",verticalAlign:"top"}}>Cerrar</Button>
            </div>            
            </Paper>
            </React.Fragment>
            }
          </Grid>
          <Grid item xs={12} md={12} align="justify">            
            {showCodiciones2 && 
            <React.Fragment>
            <Alert severity="info">Por favor lee todo el documento de las condiciones de Pago antes de aceptar.</Alert>
            <Paper  justifycontent="justify" className='' 
            style={{backgroundColor:"#F1F1F1",borderRadius:"10px" }}>       
            <div id="terminos2" className="row" >
              <PDFPago />
            </div>
            <div align="center">
              <Button variant="contained"
                    align="center" 
                    type='button' 
                    onClick={handleAceptar2}
                    sx={{margin:"10px",verticalAlign:"top"}}>Aceptar Condiciones</Button>
              <Button variant="contained"
                    align="center" 
                    type='button' 
                    onClick={()=>{setShowCodiciones2(false);}}
                    sx={{margin:"10px",verticalAlign:"top"}}>Cerrar</Button>
            </div>
            </Paper>
            </React.Fragment>
            }
          </Grid>          
        </Grid>
      </Paper>
      <Paper elevation={1} square className='mt-2 p-2 bg-gray-100' style={{background: "#E7E6E6"}} align="center">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
              <h3>Ingresa tu número de celular</h3>
              <p>Un código te llegará por SMS para continuar tu compra</p>
          </Grid>
          <Grid item xs={12} md={12}>
            <label className='labelCodigoPais text-center'>
              +56
            </label>
            <TextField                                
                name="celular"
                label="Celular"                
                defaultValue = {celular}               
                variant="filled"                
                inputProps={{ maxLength: 9, minLength: 9 }}  
                type="phone"
                onChange={handleChangeCelular}
                className='celText'
            />
            <Button 
              variant="contained" 
              type='button' 
              disabled={contadorPedidosSMS > 0 && timer > 0}
              sx={{height:"54px", marginLeft:"0px", verticalAlign:"top"}}
              onClick={ async() => {                                
                setSmsEnviado(true);
                if(celular!=""){
                  if(celular.length===9){                    
                    resetTimer();
                    setContadorPedidosSMS(contadorPedidosSMS+1);
                    var numero = "56" + celular;
                    showLoading("Enviando SMS");
                    const token = await getRecaptchaToken();
                    var data = await axios({
                      method: "POST",
                      url: `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/General/Sms/SolicitarSMS/`+numero,
                      params: { },
                      headers: {
                        Authorization: "Bearer " + tjw,
                        'Recaptcha-Token': token,
                      }
                    }).finally(() => {
                      ReactGA.event({ name:"Envia SMS", category: "Formulario" });
                      Swal.close();  
                    });                    
                    if(data.status===200){
                      if(data.data.estado===0){
                        setSmsEnviado(false);
                        setCelularUsado(numero);
                        Swal.fire({
                          title: "Exito",
                          text: data.data.mensaje,
                          icon:"success",                
                          showCloseButton: true,
                          showCancelButton: true,
                          focusConfirm: false,
                          confirmButtonText:
                              'Ok',
                          confirmButtonAriaLabel: 'Ok'
                      });
                      }else{
                        Swal.fire({
                          title: "ALERTA",
                          text: data.data.mensaje,
                          icon:"error",                
                          showCloseButton: true,
                          showCancelButton: true,
                          focusConfirm: false,
                          confirmButtonText:
                              'Ok',
                          confirmButtonAriaLabel: 'Ok'
                      });
                      }
                    }
                  }else{
                    Swal.fire({
                        title: "ALERTA",
                        text: "El celular ingresado debe tener 9 digitos, no incluya el código de país",
                        icon:"warning",                
                        showCloseButton: true,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText:
                            'Ok',
                        confirmButtonAriaLabel: 'Ok'
                    });
                  }
                  
                }else{
                  Swal.fire({
                      title: "ALERTA",
                      text: "El celular ingresado no puede quedar vacío.",
                      icon:"warning",                
                      showCloseButton: true,
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonText:
                          'Ok',
                      confirmButtonAriaLabel: 'Ok'
                  });
                }
  
              }}
              >
                Enviar
            </Button>  
          </Grid>
          {contadorPedidosSMS > 0 &&          
          <Grid item xs={12} md={12}>
            <Typography>
                {timer === 0 ?
                  "Puede volver a solicitar un mensaje de texto"
                  :
                  "Para solicitar el envío de un nuevo mensaje espere " + timer + " segundos"
                }
                
            </Typography>
          </Grid>
          }
        </Grid>
      </Paper>
      <Paper elevation={0} square className='mt-2 p-2' >
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <TextField                                
                  name="codigoSMS"
                  label="Ingresa tu código de seguridad"
                  fullWidth        
                  disabled={smsEnviado}          
                  placeholder="9111111"
                  defaultValue = {codigoSms}
                  // value={codigoSms}
                  variant="outlined"
                  onChange={handleChangeCodigoSms}
                  inputProps={{  minLength:1, maxLength: 6  }}                
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
